<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M.26 10V2c0-.966.783-1.75 1.748-1.75h11.993c.966 0 1.749.784 1.749 1.75v8A1.75 1.75 0 0 1 14 11.75H2.009A1.75 1.75 0 0 1 .26 10zm1.5 0c0 .138.11.25.248.25h11.993a.25.25 0 0 0 .249-.25V2a.25.25 0 0 0-.25-.25H2.009A.25.25 0 0 0 1.76 2v8z"
    />
    <path
      d="M6.5 3.25h-2a.75.75 0 1 0 0 1.5h2a.75.75 0 1 0 0-1.5zM9.75 7h-5.5a.75.75 0 1 0 0 1.5h5.5a.75.75 0 0 0 0-1.5z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
